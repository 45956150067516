/* frontend/src/components/Slider.css */

.slider {
    overflow: hidden;
    position: relative;
    height: 180px; /* Slider'ın yüksekliği */
    margin-bottom: 40px;
}

.slider-track {
    display: flex;
    gap: 15px; /* Elemanlar arasında boşluk */
    animation: slide 20s linear infinite;
}

.slider-item,
.slider-ad-item {
    min-width: 180px; /* İlan kutularının genişliği */
    height: 100%;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative; /* Premium badge için gerekli */
}

.slider-item:hover,
.slider-ad-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}

/* Link için stil */
.slider-item-link {
    text-decoration: none;
    color: inherit;
}

.slider-image-container {
    height: 60%; /* Görselin alanı */
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #eee; /* Altına ince bir çizgi */
}

.slider-image {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Görselin tam olarak görünmesi için */
    transition: transform 0.3s ease;
}

.slider-info {
    padding: 10px;
    text-align: center;
}

.slider-info h3 {
    font-size: 16px;
    font-weight: bold;
    color: #333;
    margin-bottom: 5px;
}

.slider-info .price {
    font-size: 14px;
    color: #e74c3c;
    font-weight: bold;
}

/* Premium badge */
.ribbon {
    position: absolute;
    top: 10px;
    left: -40px; /* Kurdelenin çapraz olması için sola kaydırıyoruz */
    width: 150px;
    height: 30px;
    background-color: #b08d57; /* Premium altın rengi */
    color: white;
    line-height: 30px;
    font-weight: bold;
    transform: rotate(-45deg); /* Çapraz durması için rotasyon veriyoruz */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Hafif gölge efekti */
    z-index: 2;
    display: flex;
    justify-content: center; /* Yazıyı ortala */
}

/* Placeholder için stil */
.placeholder-item {
    opacity: 0.6;
}

/* Sonsuz kaydırma animasyonu */
@keyframes slide {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-50%);
    }
}

/* Mobil cihazlar için uyarlamalar */
@media (max-width: 768px) {
    .slider {
        height: 150px;
        margin-bottom: 20px;
    }

    .slider-item,
    .slider-ad-item {
        min-width: 140px;
    }

    .slider-info h3 {
        font-size: 14px;
    }

    .slider-info .price {
        font-size: 12px;
    }

    .ribbon {
        width: 120px;
        height: 25px;
        font-size: 12px;
    }
}

@media (max-width: 480px) {
    .slider {
        height: 120px;
    }

    .slider-item,
    .slider-ad-item {
        min-width: 120px;
    }

    .slider-info h3 {
        font-size: 12px;
    }

    .slider-info .price {
        font-size: 10px;
    }

    .ribbon {
        width: 100px;
        height: 20px;
        font-size: 10px;
    }
}
