/* Varsayılan Masaüstü Stilleri */
.banner {
    position: relative;
    background: url('../assets/your-slider-image.jpg') no-repeat center center;
    background-size: cover;
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: white;
    overflow: hidden;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
    border-radius: 12px;
    animation: bannerFadeIn 1.5s ease-out;
}

.banner-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1;
}

.banner .banner-content {
    position: relative;
    z-index: 2;
    padding-bottom: 60px;
}

.banner .banner-content h1 {
    font-size: 52px;
    margin-bottom: 20px;
    font-weight: bold;
    letter-spacing: 1px;
    animation: textSlideIn 1s ease-out;
}

.banner .banner-content p {
    font-size: 22px;
    margin-bottom: 20px;
    animation: textSlideIn 1.2s ease-out;
}

.banner .cta-button {
    background-color: #b30000;
    color: white;
    padding: 15px 30px;
    text-decoration: none;
    font-size: 20px;
    border-radius: 8px;
    font-weight: bold;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease, background-color 0.3s ease;
    animation: buttonFadeIn 1.5s ease-out;
}

.banner .cta-button:hover {
    background-color: #ff6666;
    transform: scale(1.05);
}


/* Mobil Cihazlar İçin */
@media (max-width: 768px) {
    .banner {
        height: 300px;
    }

    .banner .banner-content h1 {
        font-size: 28px;
    }

    .banner .banner-content p {
        font-size: 16px;
    }

    .banner .cta-button {
        font-size: 16px;
        padding: 10px 20px;
    }

    .scroll-indicator {
        bottom: 20px; /* Mobilde biraz daha yukarı çekildi */
    }
}

/* Animasyonlar */
@keyframes bannerFadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes textSlideIn {
    from {
        opacity: 0;
        transform: translateY(30px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes buttonFadeIn {
    from {
        opacity: 0;
        transform: scale(0.8);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes arrowBounce {
    0%, 100% {
        transform: rotate(-45deg) translateY(0);
    }
    50% {
        transform: rotate(-45deg) translateY(10px);
    }
}

@keyframes textGlow {
    from {
        text-shadow: 0px 0px 8px rgba(255, 255, 255, 0.8);
    }
    to {
        text-shadow: 0px 0px 12px rgba(255, 255, 255, 1);
    }
}

@keyframes fadeInScroll {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}