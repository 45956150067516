/* AdPopup.css */

/* Modal arka planını karartma */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8); /* Karartma efekti */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Yüksek z-index */
    animation: fadeInOverlay 0.6s ease-out; /* Açılış animasyonu */
}

.modal-overlay.fade-out {
    animation: fadeOutOverlay 0.6s ease-out forwards;
}

/* Modal içeriği (video) */
.modal-content {
    position: relative;
    width: 90%;
    max-width: 800px;
    background-color: #000;
    border-radius: 12px;
    overflow: hidden; /* Kenarlardan taşma */
    box-shadow: 0 12px 50px rgba(0, 0, 0, 0.6);
    transition: all 0.3s ease;
    z-index: 1001; /* overlay'ın üzerinde olması için */
}

.modal-content video {
    width: 100%;
    height: auto;
    border-radius: 12px;
}

/* Kapatma butonu */
.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: rgba(139, 0, 0, 0.9); /* Daha belirgin */
    border: none;
    color: #fff; /* Beyaz renk */
    font-size: 24px; /* Daha büyük ikon */
    cursor: pointer;
    border-radius: 50%; 
    padding: 16px; /* Daha büyük dokunmatik alan */
    transition: all 0.3s ease;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2); 
    z-index: 1002; /* Modal içeriğin üzerinde */
}

.close-button:hover {
    background-color: rgba(165, 0, 0, 0.9);
    transform: scale(1.1); /* Hover sırasında hafif büyüme */
}

/* Ses aç/kapa butonu */
.mute-button {
    position: absolute;
    bottom: 15px;
    left: 15px;
    background-color: rgba(255, 255, 255, 0.2); 
    color: #fff;
    padding: 10px 14px; /* Buton boyutu artırıldı */
    font-size: 16px; /* Yazı boyutu artırıldı */
    border-radius: 20px;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2); 
}

.mute-button:hover {
    background-color: rgba(255, 255, 255, 0.8); 
    color: #000; 
}

/* Açılış ve kapanış animasyonları */
@keyframes fadeInOverlay {
    from { opacity: 0; }
    to { opacity: 1; }
}

@keyframes fadeOutOverlay {
    from { opacity: 1; }
    to { opacity: 0; }
}

/* Fade-in animasyonu */
.fade-in {
    animation: fadeInContent 0.6s ease-out;
}

@keyframes fadeInContent {
    from { opacity: 0; transform: translateY(30px); }
    to { opacity: 1; transform: translateY(0); }
}

/* Medya sorguları - Mobil ve küçük cihazlar için ayarlamalar */
@media (max-width: 600px) {
    .modal-content {
        width: 95%; /* Mobilde daha fazla yer kaplamasını sağlar */
        max-width: 95%;
    }

    .close-button {
        top: 5px;
        right: 5px;
        font-size: 20px; /* Yazı boyutu biraz küçültüldü */
        padding: 14px; /* Buton boyutu biraz küçültüldü */
    }

    .mute-button {
        bottom: 10px;
        left: 10px;
        font-size: 14px; /* Yazı boyutu küçültüldü */
        padding: 8px 12px; /* Buton boyutu küçültüldü */
    }
}

/* Genel iyileştirmeler */
.modal-content video {
    object-fit: cover; /* Video kaplamasını iyileştir */
}

/* Butonların erişilebilirliği için odaklanma stilleri */
.close-button:focus,
.mute-button:focus {
    outline: 2px solid #fff;
    outline-offset: 2px;
}