    /* src/components/ShowroomAdsTemplate.css */

    .ad-template-card {
        background: linear-gradient(135deg, #8B0000, #b30000);
        color: white;
        padding: 10px;
        border-radius: 8px;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
        transition: transform 0.3s ease, box-shadow 0.3s ease;
        position: relative;
        overflow: hidden;
        opacity: 0;
        animation: fadeIn 0.8s forwards;
    }

    .ad-template-card:hover {
        transform: translateY(-5px);
        box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
    }

    .ad-content {
        position: relative;
        z-index: 1;
    }

    .ad-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0.3;
        z-index: 0;
        transition: transform 0.3s ease;
    }

    .ad-template-card:hover .ad-image {
        transform: scale(1.05);
    }

    .ad-content h2 {
        font-size: 18px;
        margin-bottom: 10px;
    }

    .ad-content p {
        font-size: 14px;
        margin-bottom: 10px;
    }

    .ad-button {
        display: inline-block;
        padding: 10px 20px;
        background-color: white;
        color: #8B0000;
        border-radius: 4px;
        text-decoration: none;
        font-weight: bold;
        transition: background-color 0.3s ease, color 0.3s ease;
        position: relative;
        overflow: hidden;
    }

    .ad-button:hover {
        background-color: #f0f0f0;
        color: #b30000;
    }

    .ad-button::after {
        content: '';
        position: absolute;
        left: -100%;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.2);
        transition: left 0.3s;
    }

    .ad-button:hover::after {
        left: 0;
    }

    @keyframes fadeIn {
        to {
            opacity: 1;
        }
    }
