/* Varsayılan Masaüstü Stilleri */
.header {
    background-color: rgba(139, 0, 0, 0.9);
    color: white;
    padding: 20px 40px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.header-scrolled {
    background-color: rgba(139, 0, 0, 0.9);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}

.auth-page {
    background-color: rgba(255, 255, 255, 0.95);
    color: #8B0000;
}

.container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
}

.logo-image {
    width: 150px;
    height: auto;
    cursor: pointer;
}

.search-bar {
    display: flex;
    align-items: center;
    gap: 10px;
    background-color: rgba(255, 255, 255, 0.1);
    padding: 5px 15px;
    border-radius: 50px;
}

.search-bar input {
    padding: 8px;
    border: none;
    background: none;
    outline: none;
    color: white;
    font-size: 16px;
    width: 250px;
}

.search-bar input::placeholder {
    color: rgba(255, 255, 255, 0.7);
}

.search-bar .search-icon {
    background: none;
    border: none;
    color: white;
    font-size: 24px;
    cursor: pointer;
}

.user-menu {
    display: flex;
    align-items: center;
    gap: 20px;
}

.nav-links {
    display: flex;
    align-items: center;
    gap: 20px;
}

.nav-links a,
.nav-links button {
    color: white;
    background-color: transparent;
    padding: 10px 20px;
    border: 2px solid white;
    border-radius: 50px;
    text-decoration: none;
    font-weight: bold;
    cursor: pointer;
    font-size: 16px;
}

.auth-page .nav-links a,
.auth-page .nav-links button {
    color: #8B0000;
}

.nav-links a:hover,
.nav-links button:hover {
    background-color: #FFD700;
    color: black;
}

.mobile-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.95);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    transform: translateY(-100%);
    transition: transform 0.5s ease-in-out;
}

.mobile-overlay.active {
    transform: translateY(0);
}

.mobile-nav-links {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
}

.mobile-nav-links a,
.mobile-nav-links button {
    color: white;
    background-color: transparent;
    border: none;
    padding: 15px 30px;
    font-size: 24px;
    text-decoration: none;
    font-weight: bold;
    cursor: pointer;
}

.auth-page .mobile-nav-links a,
.auth-page .mobile-nav-links button {
    color: #8B0000;
}

@media (max-width: 768px) {
    .search-bar {
        display: none;
    }

    .mobile-menu-icon {
        display: block;
        font-size: 24px;
        color: white;
    }

    .auth-page .mobile-menu-icon {
        color: #8B0000;
    }

    .nav-links {
        display: none;
    }
}

@media (min-width: 769px) {
    .mobile-menu-icon {
        display: none;
    }

    .mobile-overlay {
        display: none;
    }
}

body {
    padding-top: 80px;
}