/* frontend/src/components/Footer.css */

/* Genel footer stili */
.footer {
    background-color: #1C1C1C;
    color: #FFFFFF;
    padding: 50px 20px;
    text-align: center;
    font-size: 16px;
    position: relative;
    box-shadow: 0 -4px 15px rgba(0, 0, 0, 0.1);
}

.footer .container {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 50px;
}

/* Logo alanı */
.logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer-logo {
    width: 150px;
    height: auto;
    margin-bottom: 20px;
    transition: transform 0.3s ease;
}

.footer-logo:hover {
    transform: scale(1.1); /* Logo hover'da hafif büyür */
}

/* Footer bölümlerini düzenleme */
.footer-sections {
    display: flex;
    justify-content: space-around;
    width: 100%;
    gap: 30px;
    text-align: left;
}

.footer-sections h3 {
    margin-bottom: 15px;
    font-size: 18px;
    color: #FFD700; /* Altın sarısı başlık rengi */
}

/* Hızlı linkler */
.quick-links a {
    display: block;
    margin-bottom: 10px;
    color: #FFFFFF;
    font-weight: bold;
    text-decoration: none;
    font-size: 16px;
    transition: color 0.3s ease;
}

.quick-links a:hover {
    color: #FFD700; /* Altın sarısı hover efekti */
}

/* İletişim bilgileri */
.contact-info p {
    margin: 5px 0;
    font-size: 15px;
}

.contact-info p:first-child {
    font-weight: bold;
}

/* Sosyal medya ikonları */
.social-icons {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.icons-wrapper {
    display: flex;
    gap: 20px;
}

.social-icons a {
    font-size: 24px;
    color: #FFFFFF !important; /* Simge rengi beyaz ve !important ile zorlanıyor */
    transition: color 0.3s ease, transform 0.3s ease;
}

.social-icons a:hover {
    color: #FFD700 !important; /* Hover'da altın sarısı olur ve !important ile zorlanıyor */
    transform: scale(1.1);
}

/* Footer alt kısmı */
.footer-bottom {
    margin-top: 40px;
    font-size: 14px;
    color: #B0B0B0;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    padding-top: 20px;
}

/* Mobil cihazlar için uyarlama */
@media (max-width: 768px) {
    .footer-sections {
        flex-direction: column;
        gap: 40px;
    }

    .icons-wrapper {
        justify-content: center;
    }
}