/* Ana Bölüm */
.promo-section {
    display: grid;
    grid-template-columns: 1fr 1fr; /* Solda reklam, sağda mobil uygulama */
    gap: 40px;
    padding: 50px 20px;
    background-color: #f9f9f9;
    border-radius: 15px;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
    margin-top: 50px;
}
/* Video alanı stili */
.video-container {
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}


/* Reklam Alanı Stili */
.promo-advertisement {
    background-color: #ffe8a1; /* Hafif sarı ton */
    padding: 30px;
    border-radius: 10px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.promo-advertisement h2 {
    font-size: 28px;
    color: #8B0000;
    margin-bottom: 15px;
}

.promo-advertisement p {
    font-size: 18px;
    color: #333;
    margin-bottom: 20px;
}

.ad-placeholder {
    background-color: #d4af37; /* Altın tonlu bir placeholder */
    padding: 50px;
    border-radius: 10px;
    color: white;
    font-size: 20px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
}

/* Mobil Uygulama Tanıtımı Stili */
.promo-app {
    background-color: white;
    padding: 30px;
    border-radius: 10px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.promo-app h2 {
    font-size: 28px;
    color: #8B0000;
    margin-bottom: 20px;
}

.promo-app p {
    font-size: 18px;
    margin-bottom: 25px;
    color: #333;
}

.download-buttons {
    display: flex;
    justify-content: center;
    gap: 15px;
    margin-bottom: 30px;
}

.download-button {
    background-color: #8B0000;
    color: white;
    padding: 12px 20px;
    border-radius: 50px;
    font-size: 16px;
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 10px;
    transition: background-color 0.3s ease;
}

.download-button i {
    font-size: 20px;
}

.download-button:hover {
    background-color: #B30000;
}

.promo-image-container {
    display: flex;
    justify-content: center;
}

.promo-image {
    max-width: 200px;
    width: 100%;
    border-radius: 10px;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}

/* Mobil Cihazlar için Uyarlama */
@media (max-width: 768px) {
    .promo-section {
        grid-template-columns: 1fr; /* Tek sütuna düşür */
    }

    .promo-advertisement,
    .promo-app {
        margin-bottom: 30px; /* Mobilde reklam ve uygulama arasında boşluk */
    }
}