    /* src/components/FeaturedAds.css */

    .featured-ads {
        display: grid;
        grid-template-columns: repeat(4, 1fr); /* 4 ilan yan yana olacak */
        gap: 30px;
        margin-bottom: 40px;
    }

    .featured-item-link {
        text-decoration: none;
        color: inherit;
    }

    .featured-item {
        position: relative;
        background-color: white;
        border-radius: 16px;
        box-shadow: 0 8px 30px rgba(0, 0, 0, 0.15); /* Hafif gölge efekti */
        overflow: hidden;
        padding: 0;
        transition: transform 0.3s ease, box-shadow 0.3s ease;
        cursor: pointer;
    }

    .featured-item:hover {
        transform: translateY(-12px) scale(1.03); /* Kartı büyüt ve yukarı kaldır */
        box-shadow: 0 16px 40px rgba(0, 0, 0, 0.35); /* Hover'da daha güçlü gölge */
    }

    /* Görsel kapsayıcısı */
    .featured-image-container {
        width: 100%;
        height: 200px; /* Sabit bir yükseklik belirledik */
        overflow: hidden;
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;
    }

    .featured-image {
        width: 100%;
        height: 100%;
        object-fit: cover; /* Görseli kapsayıcıya sığdır */
        transition: transform 0.3s ease;
    }

    .featured-item:hover .featured-image {
        transform: scale(1.1); /* Hover sırasında görseli büyüt */
    }

    /* Kurdela Tasarımı */
    .ribbon {
        position: absolute;
        top: 10px;
        left: -40px; /* Kurdelenin çapraz olması için sola kaydırıyoruz */
        width: 150px;
        height: 30px;
        background-color: #b08d57; /* Premium altın rengi */
        color: white;
        line-height: 30px;
        font-weight: bold;
        transform: rotate(-45deg); /* Çapraz durması için rotasyon veriyoruz */
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Hafif gölge efekti */
        z-index: 2;
        display: flex;
        justify-content: center; /* Yazıyı ortala */
    }

    /* İlan bilgileri */
    .featured-info {
        padding: 15px;
        text-align: left;
    }

    .featured-info h3 {
        font-size: 18px;
        color: #333;
        margin-bottom: 8px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .featured-info .price {
        font-size: 16px;
        color: #e74c3c;
        font-weight: bold;
        margin-bottom: 5px;
    }

    .featured-info .location {
        font-size: 14px;
        color: #777;
    }

    /* Fade-in animasyonu ekle */
    .featured-item {
        opacity: 0;
        animation: fadeIn 0.6s forwards; /* İlan kartları görünerek gelecek */
        animation-delay: calc(0.1s * var(--i)); /* Her bir kartın görünme süresini geciktir */
    }

    @keyframes fadeIn {
        from {
            opacity: 0;
            transform: translateY(20px);
        }
        to {
            opacity: 1;
            transform: translateY(0);
        }
    }

    @media (max-width: 1024px) {
        .featured-ads {
            grid-template-columns: repeat(2, 1fr); /* Tabletlerde 2 sütun */
        }
    }

    @media (max-width: 600px) {
        .featured-ads {
            grid-template-columns: 1fr; /* Mobilde tek sütun */
        }
    }
